<template>
  <template v-if="deviceType === 'desktop'">
    <div class="container-fluid vh-100 fm-background-main-color">
      <Navbar v-bind:title="'Moje konto'"></Navbar>

      <template v-if="isPasswordChanged">
        <div class="row">
          <div class="col-4"></div>
          <div class="col-4 mt-5">
            <div class="card fm-desktop-card">
              <div class="card-body text-start">
                <h5 class="card-title fm-label-main-color fm-desktop-card-title text-start">Hasło zmienione!</h5>
                <p class="card-title fm-label-main-color fm-desktop-card-form-label text-start">Mamy nadzieję, że je zapamiętasz!</p>
                <ButtonActionPrimary v-on:click="backToProfile()">Wróć do mojego konta</ButtonActionPrimary>
              </div>
            </div>
          </div>
          <div class="col-4"></div>
        </div>
      </template>

      <template v-else>
        <div class="row">
          <div class="col-4"></div>
          <div class="col-4 mt-5">
            <div class="card fm-desktop-card">
              <div class="card-body text-start">
                <form @submit.prevent="changePassword()"
                      oninput='repeatedPassword.setCustomValidity(password.value !== repeatedPassword.value ? "Hasła nie są takie same" : "")'>
                  <h5 class="card-title fm-label-main-color fm-desktop-card-title text-start">Zmień hasło</h5>
                  <p class="card-title fm-label-main-color fm-desktop-card-form-label text-start">Wpisz nowe hasło</p>
                  <div class="input-group mb-3">
                    <input v-model="password"
                           type="password"
                           class="form-control border-end-0 shadow-none"
                           id="password"
                           placeholder="Hasło"
                           @focusin="isPasswordInputFocused = true"
                           @focusout="isPasswordInputFocused = false"
                    >
                    <span class="input-group-text border-start-0 fm-input-group-text "
                          v-bind:class="{'fm-form-control-focused': isPasswordInputFocused}"
                          style="background-color: white"
                          id="togglePasswordSpan">
                      <i class="bi bi-eye"
                         id="togglePassword"
                         v-on:click="togglePassword('password')">
                      </i>
                    </span>
                  </div>
                  <p class="card-title fm-label-main-color fm-desktop-card-form-label text-start">Powtórz nowe hasło</p>
                  <div class="input-group mb-3">
                    <input v-model="repeatedPassword"
                           type="password"
                           class="form-control border-end-0 shadow-none"
                           id="repeatedPassword"
                           placeholder="Hasło"
                           @focusin="isRepeatedPasswordInputFocused = true"
                           @focusout="isRepeatedPasswordInputFocused = false"
                    >
                    <span class="input-group-text border-start-0 fm-input-group-text "
                          v-bind:class="{'fm-form-control-focused': isRepeatedPasswordInputFocused}"
                          style="background-color: white"
                          id="togglePasswordSpan">
                      <i class="bi bi-eye"
                         id="togglePassword"
                         v-on:click="togglePassword('repeatedPassword')">
                      </i>
                    </span>
                  </div>
                  <ButtonPrimary :button-text="'Zmień hasło'" :type="'submit'"></ButtonPrimary>
                </form>
              </div>
            </div>
          </div>
          <div class="col-4"></div>
        </div>
      </template>
    </div>

  </template>

  <template v-if="deviceType === 'mobile'">
    <div class="container fm-background-main-color min-vh-100">
      <template v-if="isPasswordChanged">

        <div class="row text-start">
          <div class="col-12 fm-changed-password-label">
            <span class="fm-label-main-color fm-main-label">Hasło zmienione</span>
          </div>
        </div>

        <div class="row text-start">
          <div class="col-12 fm-changed-password-message-row">
            <span class="fm-label">Mamy nadzieję, że je pamiętasz!</span>
          </div>
        </div>

        <a class="btn btn-primary w-100 fm-button-main-background-color fm-form-button-margin fm-back-to-my-account-button"
           href="/profile"
           role="button">
          Wróć do Mojego Konta
        </a>

      </template>

      <template v-else>
        <Navbar v-bind:title="'Moje konto'"></Navbar>

        <div class="row text-start fm-my-account-main-label-row">
          <div class="col-12">
            <span class="fm-label-main-color fm-main-label">Zmień hasło</span>
          </div>
        </div>

        <div class="row fm-email-input-margin">
          <div class="col-md-3"></div>
          <div class="col-md-6 fm-z-index-10">
            <form @submit.prevent="changePassword()"
                  oninput='repeatedPassword.setCustomValidity(password.value !== repeatedPassword.value ? "Hasła nie są takie same" : "")'>
              <div class="mb-3 text-start">
                <label for="new-password" class="form-label fm-label">Wpisz nowe hasło</label>
                <input v-model="password" type="password" class="form-control" id="new-password" name="password" placeholder="Nowe hasło" required>
              </div>

              <div class="mb-3 text-start">
                <label for="repeatedPassword" class="form-label fm-label">Powtórz nowe hasło</label>
                <input v-model="repeatedPassword" type="password" class="form-control" id="repeatedPassword" name="repeatedPassword" placeholder="Nowe hasło" required>
              </div>

              <button type="submit" class="btn btn-primary w-100 fm-login-button fm-button-main-background-color fm-form-button-margin">
                Zmień hasło
              </button>

            </form>
          </div>
          <div class="col-md-3"></div>
        </div>
      </template>

      <div class="row">
        <div class="col-12">
          <div class="fixed-bottom">
            <img v-bind:src="'images/padlock-with-key.svg'" style="position: fixed; bottom: 4rem; right:0;">
          </div>
        </div>
      </div>

    </div>
  </template>

</template>

<script>
import Navbar from '../components/Navbar';
import UserService from '../services/userService';
import ButtonPrimary from '../components/Utils/Atom/ButtonPrimary';
import ButtonActionPrimary from '../components/Utils/Atom/ButtonActionPrimary';
export default {
  name: 'ChangePassword',
  components: { ButtonActionPrimary, ButtonPrimary, Navbar },
  data() {
    return {
      isPasswordChanged: null,
      password: null,
      repeatedPassword: null,
      deviceType: 'desktop',
      isPasswordInputFocused: false,
      isRepeatedPasswordInputFocused: false,
      passwordResetToken: null
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();

    const queryParams = this.$route.query;
    this.passwordResetToken = queryParams.token ?? null;
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    },
    async changePassword() {
      const response = await UserService.changePassword(this.password, this.passwordResetToken);
      this.isPasswordChanged = response.data.isSuccess;

      if (this.isPasswordChanged && this.passwordResetToken) {
        await this.$router.push('/');
      }
    },
    // TODO: togglePassword function should be global function same for all password inputs
    togglePassword(passwordId) {
      const togglePasswordElement = document.getElementById('togglePassword');
      const passwordElement = document.getElementById(passwordId);

      if (togglePasswordElement.classList.contains('bi-eye-slash')) {
        togglePasswordElement.classList.remove('bi-eye-slash');
        togglePasswordElement.classList.add('bi-eye');
        passwordElement.setAttribute('type', 'password');
      } else {
        togglePasswordElement.classList.remove('bi-eye');
        togglePasswordElement.classList.add('bi-eye-slash');
        passwordElement.setAttribute('type', 'text');
      }
    },
    backToProfile() {
      this.$router.push('/profile');
    }
  }
};
</script>

<style scoped>
.fm-my-account-main-label-row {
  margin-top: 1.5rem;
}

.fm-changed-password-label {
  padding-top: 1.5rem;
}

.fm-changed-password-message-row {
  padding-top: 1rem;
}

.fm-label {
  color: var(--dark-gunmetal);
}

.fm-back-to-my-account-button {
  margin-top: 1.5rem;
}

.fm-desktop-card {
  border-radius: 2rem;
  padding-left: 1rem;
}

.fm-desktop-card-title {
  font-size: 1.75rem;
  font-weight: 600;
}

.fm-desktop-card-form-label {
  color: var(--dark-gunmetal);
  font-weight: 400;
  font-size: 1rem;
}

/*.fm-desktop-card-login {*/
/*  font-size: 1.25rem;*/
/*  font-weight: 700;*/
/*}*/

.fm-desktop-link {
  text-decoration: none;
  color: var(--royal-orange);
  cursor: pointer;
}

.fm-desktop-button {
  border-radius: 0.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  height: 4rem;
  margin-top: 1.5rem;
}
</style>
